<template>
  <div>
    <Tab :tablist="list" @change="changeTab" @constDetail="openConstDetailPop" @accountDetail="openAccountDetailPop" @serverDialog="openServerDialog" :tabCurrent="current" />
    <Content
      v-for="(item,index) in list"
      v-if="index == current"
      :key="index"
      :tabContent="item"
      :isSearching="isSearching"
      @search="searchId"
    />
    <Table :data="tableDatas" :sureflag="isSureDownload" :downflag="isDownloading" :clearflag="isClearFlag" v-if="showTable" v-loading="tableLoading"
           @noBanlance="noBanlances" @onDownload="downloadId" @onSureDown="changeDown" />
    <Dialog :dialogVisible="dialogTableVisible" @closeDialog="closeConstDetailPop" />
    <Accountdialog :dialogVisible="accountDialogTableVisible" @closeDialog="closeAccountDetailPop" />
  </div>
</template>

<script>
import Tab from "./module/tab.vue";
import Table from "./module/table.vue";
import Content from "./module/content.vue";
import Dialog from "./module/dialog.vue";
import Accountdialog from "./module/accountdialog";
import { searchImage,buy } from "../../api/downLoad";
import { message } from '@/utils/resetMessage';

export default {
  components: {
    Tab,
    Table,
    Content,
    Dialog,
    Accountdialog
  },
  data() {
    return {
      getAll_status:0, //状态
      isLoggedIn:false,
      current: 0,
      webType: "home",
      list: [
        {
          title: "千图网",
          subTitle: "千图网",
          titleState: "success",
          tip:'<ul><li>海各素材请到左侧的【素材下载区】下载</li> <li>昵图网，我图网的人民币素材无法下载</li> <li>国内素材网文件不保存服务器，重复提交会再次扣费，请您下载后保存好文件，感谢理解。</li></ul>',
          label: null,
          webType: "shutterstock_V2",
          icon: require("@/assets/images/download/ic1_1.png"),
          status:true
        },
        {
          title: "包图网",
          subTitle: "包图网",
          titleState: "success",
          label: null,
          webType: "adobestock",
          icon: require("@/assets/images/download/ic8_8.png"),
          status:true
        },
        {
          title: "六图网",
          subTitle: "六图网",
          titleState: "success",
          label: null,
          webType: "istockphoto",
          icon: require("@/assets/images/download/ic5_5.png"),
          status:true
        },
        {
          title: "昵图网",
          subTitle: "昵图网",
          titleState: "success",
          label: null,
          webType: "depositphotos",
          icon: require("@/assets/images/download/ic2_2.png"),
          status:true
        },
        {
          title: "千库视听",
          subTitle: "千库视听",
          titleState: "success",
          label: null,
          webType: "dreamstime",
          icon: require("@/assets/images/download/ic6_6.png"),
          status:true
        },
        {
          title: "千库网",
          subTitle: "千库网",
          titleState: "success",
          label: null,
          webType: "rfoto",
          icon: require("@/assets/images/download/ic3_3.png"),
          status:true
        },
        {
          title: "摄图网",
          subTitle: "摄图网",
          titleState: "success",
          tip:"ss视频下载目前仅支持HD普通视频下载，如需严选或4K视频请联系客服下载",
          label: null,
          webType: "vedio",
          icon: require("@/assets/images/download/ic1_1.png"),
          status:true
        },
        {
          title: "我图网",
          subTitle: "我图网",
          titleState: "success",
          tip:"ss视频下载目前仅支持HD普通视频下载，如需严选或4K视频请联系客服下载",
          label: null,
          webType: "home",
          icon: require("@/assets/images/download/ic1_1.png"),
          status:true
        },
        {
          title: "熊猫办公",
          subTitle: "熊猫办公",
          titleState: "success",
          tip:"ss视频下载目前仅支持HD普通视频下载，如需严选或4K视频请联系客服下载",
          label: null,
          webType: "home",
          icon: require("@/assets/images/download/ic1_1.png"),
          status:true
        },
        // {
        //   title: "众图网",
        //   subTitle: "众图网",
        //   titleState: "success",
        //   tip:"shutterstock视频下载目前仅支持HD普通视频下载，如需严选或4K视频请联系客服下载",
        //   label: null,
        //   webType: "home",
        //   icon: require("@/assets/images/download/ic1_1.png"),
        //   status:true
        // },
        {
          title: "90设计",
          subTitle: "90设计",
          titleState: "success",
          tip:"ss视频下载目前仅支持HD普通视频下载，如需严选或4K视频请联系客服下载",
          label: null,
          webType: "home",
          icon: require("@/assets/images/download/ic1_1.png"),
          status:true
        },
        {
          title: "办公资源",
          subTitle: "办公资源",
          titleState: "success",
          tip:"ss视频下载目前仅支持HD普通视频下载，如需严选或4K视频请联系客服下载",
          label: null,
          webType: "home",
          icon: require("@/assets/images/download/ic1_1.png"),
          status:true
        }
      ],
      tableDatas: [],
      showTable: false, //是否显示表格
      tableLoading: false, //加载数据效果
      dialogTableVisible: false, //费用明细弹窗
      accountDialogTableVisible: false, //余额弹窗
      serverDialogVisible: false, //客服弹窗
      form: {
        webType: "",
        imageID: ""
      },
      dataForm:{
        img: '', //缩略图
        id: '', //图片ID
        imgSize: "", //图片大小
        complete: false, //操作状态为按钮
        loading: false, //操作状态为加载
        saleType: "", //默认为空
        price: 0, //图片价格
        orgPrice:'',//原价
        balance: "", //用户余额
        value: 4, //图片类型默认值
        disabled: false, //是否可选
        options: [], //图片类型
        webType: this.webType, //网站类型
        downurl: "", //主下载链接
        downurl2: "", //海外下载链接
        type : true, //搜索ID是否正常
        msg:'', //提示消息
        error:false //下载是否出错
      },
      timer:null, //定时器
      isSearching:false, // 是否在搜索
      searchCount:0, //搜索数量
      isDownloading:false, // 是否正在下载
      isSureDownload:false, // 是否确认下载
      isClearFlag:false //是否需要清除Url
    };
  },
  mounted() {
    this.isLoggedIn = (this.$store.state.user.token !== null || this.$store.state.user.token !== "")
    && (this.$store.state.user.loginNameType!=null )? true : false;

    let userName="",userEmail="";
    if(this.$store.state.common.userInfo.mobile){
      userName=this.$store.state.common.userInfo.mobile;
    }
    if(this.$store.state.common.userInfo.mail){
      userEmail=this.$store.state.common.userInfo.mail;
    }

    userName=userName?userName:userEmail; //为空则赋一样的值
    userEmail=userEmail?userEmail:userName+"@pptuku.com";//为空需要加邮箱后缀，否则无法修改用户

  },
  beforeDestroy(){
    clearTimeout(this.timer);
     this.timer = null;
  },
  methods: {
    //切换tab
    changeTab(index) {
      this.current = index;
      this.webType = this.list[this.current].webType;
      this.tableDatas = [];
      this.isSearching = false;
      this.isSureDownload = false;//是否确认下载
      this.showTable = false; //是否显示表格
      this.tableLoading = false; //加载数据效果
      this.dialogTableVisible = false; //费用明细弹窗
      this.accountDialogTableVisible = false; //余额弹窗
    },
    //打开客服下载提示
    openServerDialog(){
      this.serverDialogVisible = true;
    },
    closeServerDialod(){
      this.serverDialogVisible = false;
    },
    //费用明细
    openConstDetailPop() {
      this.dialogTableVisible = true;
    },
    closeConstDetailPop() {
      this.dialogTableVisible = false;
    },
    //余额明细
    openAccountDetailPop() {
      this.accountDialogTableVisible = true;
    },
    closeAccountDetailPop() {
      this.accountDialogTableVisible = false;
    },
    //获取表格数据
    getTableDatas(result) {
      this.dataForm = {
        img: '', //缩略图
        id: '', //图片ID
        imgSize: "", //图片大小
        complete: false, //操作状态为按钮
        loading: false, //操作状态为加载
        saleType: "", //默认为空
        price: 0, //图片价格
        orgPrice:'',//原价
        balance: "", //用户余额
        value: 4, //图片类型默认值
        disabled: false, //是否可选
        options: [], //图片类型
        webType: this.webType, //网站类型
        downurl: "", //主下载链接
        downurl2: "", //海外下载链接
        type : true, //正常
        msg:'', //提示消息
        title:'' // 标题信息
      }
      //请求成功后赋值，并且去掉加载效果
      if(result.Dflag){
        this.dataForm.id = result.recCode;
        this.dataForm.price = result.price;
        this.dataForm.title = result.title;
        this.dataForm.complete = true;
        this.dataForm.loading = false;
        this.dataForm.downurl = result.downloadUrl;
        this.dataForm.value = 1;
        this.dataForm.options = [
          {value: 1, label: "ZIP"}
        ];
      }else{
        this.dataForm.id = result.recCode;
        this.dataForm.msg = result.msg;
        this.dataForm.type = false;
      }
      this.tableDatas.push(this.dataForm);
      this.tableLoading = false;
    },
    //下载ID
    downloadId(flag){
      this.isSearching = flag;
      this.isDownloading = flag;
    },
    //改变下载状态
    changeDown(flag){
      this.isSureDownload = flag;
    },
    //搜索ID
    async searchId(prams) {
      if(!this.isLoggedIn){
        message.error({
          message: '您还没登录用户，请登录',
          showClose: true,
          duration: 2000,
          offset: 100
        });
        return ;
      }
      this.isClearFlag = true;
      this.isSearching = true;
      this.isDownloading = true;
      this.isSureDownload = false;
      this.tableDatas = [];
      this.showTable = true;
      this.tableLoading = true;
      var len = 0;
      let mblance = this.$store.state.common.userInfo.blance;

      if(mblance < 0.5){
        message.error({
          message: '您的余额已经不足，请及时充值',
          showClose: true,
          duration: 2000,
          offset: 100
        });
        return;
      }
      //根据余额限制搜索数量
      if(mblance<=50&&prams.length>30){//0-50元余额用户限制一次最多搜索30个
        message.error({
          message: '一次性最多只能搜索30个ID',
          showClose: true,
          duration: 2000,
          offset: 100
        });
        this.downloadId(false);
        this.showTable=false;
        this.tableLoading=false; //加载数据效果
        return;
      }else if(mblance<=100&&prams.length>70){//50-100元余额用户限制一次最多搜索70个
        message.error({
          message: '一次性最多只能搜索70个ID',
          showClose: true,
          duration: 2000,
          offset: 100
        });
        this.downloadId(false);
        this.showTable=false;
        this.tableLoading=false; //加载数据效果
        return;
      }else { // 100元以上用户不作限制

      }
      //提交下载
      let buydata = {
        imageID:prams[0],
        webType:this.webType,
        format:0 //默认为0
      }
      buy(buydata).then(result => {
        this.getTableDatas(result);
        this.isSearching = false;
        this.isDownloading = false;
        this.isClearFlag = false;
        this.tableLoading = false;
      }).catch(error => {
        this.isSearching = false;
        this.isDownloading = false;
        this.isClearFlag = false;
        this.tableLoading = false;
      });
    },
    noBanlances(){//余额不足
      this.tableDatas = [];
      this.isSureDownload = false;
    },

  }
};
/*var tawks1,tawks0;
(function(){
  //console.log("test111","embed.tawk.to");
  //tawks1=document.createElement("script"),tawks0=document.getElementsByTagName("script")[0];
  //放到onMounted里面设置，不然没法设置用户名
  // tawks1.async=true;
  // tawks1.src='https://embed.tawk.to/5e883faf69e9320caac026bc/default';
  // tawks1.charset='UTF-8';
  // tawks1.setAttribute('crossorigin','*');
  //tawks0.parentNode.insertBefore(tawks1,tawks0);
})();*/

</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  ::v-deep li{
    list-style: initial !important;
  }
  ::v-deep ul {
    padding: 0 10px;
  }
</style>
